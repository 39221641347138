import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import mapEditDriver from 'api/mta/quoteToFormMappings/mapEditDriver';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import MtaDriverForm from 'forms/MtaForms/MtaDriverForm';
import { useEditDriverMtaDetails } from 'state/formData/mta/editDriver';
import { useMtaQuote } from 'state/mta/mtaQuote';
import { usePolicyData } from 'state/policy/policy';
import { CsHero } from 'types/contentStack';
import { getEditableDriver } from './getEditableDriver';

type MtaEditDriverPageContent = {
  csMtaEditDriver: {
    hero: CsHero;
    next_button_text: string;
  };
  csMtaStartDate: {
    next_button_text: string;
  };
};

export const query = graphql`
  query {
    csMtaEditDriver {
      hero {
        heading
        subheading
      }
      next_button_text
    }
    csMtaStartDate {
      next_button_text
    }
  }
`;

type MtaEditDriverPageProps = {
  hasExistingMtaRequest: boolean;
  moveNext: () => void;
  driverId: string;
};

const MtaEditDriverPage: React.FC<MtaEditDriverPageProps> = ({
  hasExistingMtaRequest,
  moveNext,
  driverId,
}) => {
  const {
    csMtaEditDriver: {
      hero: { heading, subheading },
      next_button_text,
    },
    csMtaStartDate: { next_button_text: startDateNextButtonText },
  } = useStaticQuery<MtaEditDriverPageContent>(query);
  const [driverDetails, updateDriverDetails] = useEditDriverMtaDetails();
  const policy = usePolicyData();
  const mtaQuote = useMtaQuote();

  useEffect(() => {
    const [driver, migratedDriverFields] = getEditableDriver(driverId, policy, mtaQuote);
    if (driver) {
      updateDriverDetails(mapEditDriver(driver, migratedDriverFields));
    } else {
      throw new Error(`Driver with id "${driverId}" not found`);
    }
  }, [driverId, policy, mtaQuote, updateDriverDetails]);

  return (
    <>
      <HeroPlain heading={heading} subhead={subheading} />
      <MtaDriverForm
        formState={driverDetails}
        updateFormState={updateDriverDetails}
        moveNextText={hasExistingMtaRequest ? startDateNextButtonText : next_button_text}
        moveNext={moveNext}
      />
    </>
  );
};

export default MtaEditDriverPage;
