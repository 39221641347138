import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { graphql, PageProps } from 'gatsby';
import React, { useEffect } from 'react';
import LoadMtaQuoteWrapper from 'components/ApiRequestWrapper/LoadMtaQuoteWrapper';
import LoadPolicyWrapper from 'components/ApiRequestWrapper/LoadPolicyWrapper';
import Layout from 'components/Layout';
import MtaEditDriverPage from 'components/MtaEditDriver/MtaEditDriverPage';
import MtaStartDatePage from 'components/MtaStartDate/MtaStartDatePage';
import useMtaStartDatePage from 'components/MtaStartDate/useMtaStartDatePage';
import { trackCheckoutEvent } from 'helpers/ecommerceTracking';
import { getQueryParam } from 'helpers/getQueryParam';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { useUser } from 'state/user/state';
import { CsMeta } from 'types/contentStack';

type EditDriverProps = {
  data: {
    csMtaEditDriver: {
      meta: CsMeta;
    };
  };
};

export const query = graphql`
  query {
    csMtaEditDriver {
      meta {
        meta_title
      }
    }
  }
`;

const EditDriver: React.FC<EditDriverProps & PolicyNumberProps & PageProps> = ({
  data: {
    csMtaEditDriver: { meta },
  },
  policyNumber,
  location,
}) => {
  const driverId = getQueryParam(location, 'driverId');

  useEffect(() => {
    if (!driverId) {
      throw new Error('DriverId query param missing');
    }
  }, [driverId]);

  const { isLoading, loadingMessage, requestHandler } = useApiRequestHandler();
  const {
    startDateFormProps,
    hasExistingMtaRequest,
    showStartDatePage,
    toggleStartDatePage,
    moveNextOrGenerateMta,
  } = useMtaStartDatePage(policyNumber, 'Edit a driver', requestHandler);

  useEffect(() => trackCheckoutEvent(1, null, 'MTA'), []);
  const [user] = useUser();
  usePageTracking(meta.meta_title, !!user?.isLoggedIn);

  return (
    <Layout meta={meta} pageType="mtaAndRenewal">
      <LoadPolicyWrapper policyNumber={policyNumber}>
        <LoadMtaQuoteWrapper policyNumber={policyNumber}>
          {isLoading && <LoadingOverlay loadingMessage={loadingMessage || ''} />}
          {showStartDatePage ? (
            <MtaStartDatePage
              formState={startDateFormProps.formState}
              updateFormState={startDateFormProps.updateFormState}
              moveNext={moveNextOrGenerateMta}
              moveBack={toggleStartDatePage}
            />
          ) : (
            driverId && (
              <MtaEditDriverPage
                hasExistingMtaRequest={hasExistingMtaRequest}
                moveNext={moveNextOrGenerateMta}
                driverId={driverId}
              />
            )
          )}
        </LoadMtaQuoteWrapper>
      </LoadPolicyWrapper>
    </Layout>
  );
};

export default withPolicyNumber(EditDriver);
