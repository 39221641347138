import { QuoteAdditionalDriverDetails } from 'api/quote/quoteRequest';
import { getMigratedAdditionalDriverDetailsFlags } from 'helpers/migratedPolicyHelpers';
import { MtaQuote } from 'state/mta/mtaQuote';
import { MigratedAdditionalDriverDetailsFlags, Policy } from 'state/policy/policy';

export const getEditableDriver = (
  driverId: string,
  policy: Policy | null,
  mtaQuote: MtaQuote | null
): [
  QuoteAdditionalDriverDetails | null,
  MigratedAdditionalDriverDetailsFlags | undefined
] => {
  const latestAdditionalDriverDetails = (mtaQuote ?? policy)?.additionalDrivers;
  const driver = latestAdditionalDriverDetails?.find((d) => d.driverId === driverId);

  return [
    driver ?? null,
    policy?.isMigrated && policy.migratedFields?.additionalDrivers
      ? getMigratedAdditionalDriverDetailsFlags(
          driverId,
          policy.migratedFields?.additionalDrivers
        )
      : undefined,
  ];
};
