import { QuoteAdditionalDriverDetails } from 'api/quote/quoteRequest';
import { mapSingleAdditionalDriver } from 'api/quote/quoteToFormMappings/mapAdditionalDrivers';
import { EditDriverMtaDetails } from 'state/formData/mta/editDriver';
import { MigratedAdditionalDriverDetailsFlags } from 'state/policy/policy';

const mapEditDriver = (
  quoteAdditionalDriver: QuoteAdditionalDriverDetails,
  migratedAdditionalDriverDetailsFields?: MigratedAdditionalDriverDetailsFlags
): EditDriverMtaDetails => ({
  ...mapSingleAdditionalDriver(quoteAdditionalDriver),
  driverId: quoteAdditionalDriver.driverId ?? '',
  migratedFields: migratedAdditionalDriverDetailsFields,
});

export default mapEditDriver;
